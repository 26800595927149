import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import imageNotfound from "../../src/assets/images/broken-1.png";

/* material ui */
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import axios from "axios";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

/* material ui css */
const useStyles = makeStyles((theme) => ({
  product_grid: {
    fontFamily: "Fira Sans',sans-serif",
    textAlign: "center",
    padding: "0 0 72px",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    overflow: "hidden",
    position: "relative",
    transition: "all 0.4s ease 0s",
    zIndex: 1,
    margin: 15,
    "&:hover": {
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
      "& $productContent": {
        bottom: 0,
      },
    },
    [theme.breakpoints.down('sm')]: {
      margin: 8,
      padding: "0 0 60px",
    },
  },
  product_image: {
    position: "relative",
    transition: "all .3s ease 0s",
    width: "100%",
  },
  image: {
    width: "100%",
    height: "15rem",
    opacity: 1,
    transition: "all .3s ease-out 0s",
    [theme.breakpoints.down('sm')]: {
      height: "12rem",
    },
    [theme.breakpoints.down('xs')]: {
      height: "10rem",
    },
  },
  productBargain: {
    color: "#fff",
    backgroundColor: "#ef5777",
    fontSize: 12,
    textTransform: "uppercase",
    padding: "2px 6px",
    display: "block",
    position: "absolute",
    top: "10px",
    left: 0,
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  productLeft: {
    color: "#fff",
    backgroundColor: "#333",
    fontSize: 12,
    textTransform: "uppercase",
    padding: "2px 7px",
    display: "block",
    position: "absolute",
    bottom: 0,
    left: "auto",
    right: 0,
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  productRight: {
    color: "#fff",
    backgroundColor: "#333",
    fontSize: 12,
    textTransform: "uppercase",
    padding: "2px 7px",
    display: "block",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: "auto",
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  rating: {
    padding: "12px 0 0",
    margin: 2,
    listStyle: "none",
    position: "relative",
    zIndex: 1,
    [theme.breakpoints.down('xs')]: {
      padding: "8px 0 0",
    },
  },
  productContent: {
    backgroundColor: "#fff",
    textAlign: "center",
    padding: "12px 0",
    margin: "0 auto",
    position: "absolute",
    left: 0,
    right: 0,
    bottom: "-30px",
    zIndex: 1,
    transition: "all .3s",
    "&:hover": {
      bottom: 0,
    },
    [theme.breakpoints.down('sm')]: {
      padding: "8px 0",
      bottom: "-25px",
    },
  },
  title: {
    fontSize: 13,
    fontWeight: 400,
    color: "#333",
    letterSpacing: ".5px",
    textTransform: "capitalize",
    margin: "0 10px 10px",
    transition: "all .3s ease 0s",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    "&:hover": {
      color: "#333",
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      margin: "0 5px 8px",
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 11,
    },
  },
  priceLabel: {
    color: "#333",
    fontSize: 16,
    fontFamily: "Fira Sans',sans-serif",
    fontWeight: 600,
    letterSpacing: ".6px",
    marginBottom: 8,
    textAlign: "center",
    transition: "all .3s",
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      marginBottom: 6,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
  },
  price: {
    color: "#999",
    fontSize: 17,
    fontFamily: "Fira Sans',sans-serif",
    fontWeight: 700,
    letterSpacing: ".6px",
    marginLeft: 3,
    display: "inline-block",
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  cartButton: {
    color: "#000",
    fontSize: 13,
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  soldout: {
    fontSize: 16,
    borderRadius: "2px",
    backgroundColor: "#fff",
    boxShadow: "0 1px 3px 0 rgb(0 0 0 / 12%)",
    textAlign: "center",
    textTransform: "uppercase",
    pointerEvents: "none",
    color: "#27B23E",
    width: 150,
    margin: 0,
    padding: 0,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateY(-50%) translateX(-50%)",
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      width: 130,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      width: 110,
    },
  },
  sizeLabel: {
    color: "#333",
    fontSize: 12,
    fontFamily: "Fira Sans',sans-serif",
    fontWeight: 600,
    letterSpacing: ".6px",
    transition: "all .3s",
    display: "flex",
    justifyContent: "center",
    lineHeight: 1.7,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
      flexWrap: 'wrap',
    },
  },
  size: {
    fontSize: 12,
    fontWeight: 500,
    fontFamily: "Fira Sans',sans-serif",
    color: "#383838",
    textTransform: "none",
    padding: 2,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
}));

const imageBaseUrl = process.env.REACT_APP_IMAGE_URL;
const bunnyCdnUrl = process.env.REACT_APP_BUNNY_CDN_URL;

export const DemoItem = (props) => {
  const classes = useStyles();
  const { product } = props;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [sizeColorQuantity, setSizeColorQuantity] = useState();
  const [imageUrls, setImageUrls] = useState([]);

  /* bargian time left */
  const timeDiffCalc = (dateFuture, dateNow) => {
    let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    let difference = "";
    if (days > 0) {
      difference += days === 1 ? `${days}d: ` : `${days}d: `;
    }

    difference += hours === 0 || hours === 1 ? `${hours}h: ` : `${hours}h: `;

    difference += minutes === 0 || hours === 1 ? `${minutes}m` : `${minutes}m`;

    return difference;
  };

  const getDefaultImage = async (productsList) => {
    let defaultProduct = productsList.attributes.map((productItem) => 
      productItem.attributeOptionValues.filter((item) => 
        item.optionValueDefault && item.optionValueDefault === true
      )
    ).flat();
    return defaultProduct[0]?.images[0]?.imageUrl;
  }

  const getPrice = (productsList) => {
    let defaultProduct = productsList.attributes.map((productItem) => 
      productItem.attributeOptionValues.filter((item) => 
        item.optionValueDefault && item.optionValueDefault === true
      )
    ).flat();
    return defaultProduct && defaultProduct[0]?.attributePrice;
  }

  useEffect(() => {
    const fetchImageUrls = async () => {
      const defaultProduct = product?.attributes
        ?.map((productItem) => 
          productItem.attributeOptionValues.filter((item) => 
            item.optionValueDefault === true
          )
        )
        ?.flat();

      const imagePromises = defaultProduct.map(async (item) => {
        const imageUrl = item?.images?.[0]?.imageUrl;
        if (imageUrl) {
          try {
            const result = `${imageUrl}`;
            return result;
          } catch (error) {
            return null;
          }
        }
        return null;
      });

      const resolvedImageUrls = await Promise.all(imagePromises);
      setImageUrls(resolvedImageUrls.filter(url => url !== null));
    };

    fetchImageUrls();
  }, [product]);

  const getMinimumOrderQuantity = (productItem) => {
    let MOQ = productItem.attributes.map((item) => 
      item.attributeOptionValues.filter((info) => info.minQuantity)
    ).flat();
    return MOQ && MOQ[0]?.minQuantity;
  }

  useEffect(() => {
    let quantity = product.attributes.map((item) => 
      item.attributeOptionValues.filter((info) => info.attributeQuantity !== undefined)
    ).flat();
    let quant = quantity && quantity[0]?.attributeQuantity;
    setSizeColorQuantity(quant);
  }, [product]);

  return (
    <Link
      to={`/product/${product.categories[0].code
        }/${product.description.name.replace(/ /g, "-")}/${product.id}`}
      style={{
        textDecoration: "none",
      }}
    >
      <div className={classes.product_grid}>
        <div className={classes.product_image}>
          {product.attributes && product.attributes.length > 0 ? (
            imageUrls.length > 0 ? (
              <img 
                className={classes.image} 
                src={imageUrls[0]?.includes(bunnyCdnUrl) ? `${imageUrls[0]}` : `${bunnyCdnUrl}${imageUrls[0]}`} 
                alt={product.description.name} 
                loading="lazy" // Add lazy loading
              />
            ) : (
              <img
                className={classes.image}
                src={imageNotfound}
                alt={product.description.name}
                loading="lazy" // Add lazy loading
              />
            )
          ) : null}

          {product.bargain === "YES" &&
            new Date(product.bargainEndDateTime) > new Date() && (
              <div>
                <Typography component="div" className={classes.productBargain}>
                  Bargain{" "}
                  <span
                    style={{
                      fontFamily: "Fira Sans',sans-serif",
                      fontSize: isSmallScreen ? 10 : 12,
                      textTransform: "initial",
                    }}
                  >
                    (
                    {timeDiffCalc(
                      new Date(product.bargainEndDateTime),
                      new Date()
                    )}{" "}
                    left)
                  </span>
                </Typography>
              </div>
            )}
          <Typography component="span" className={classes.productRight}>
            MOQ {getMinimumOrderQuantity(product)}
          </Typography>

          <Typography component="span" className={classes.productLeft}>
            {sizeColorQuantity} left
          </Typography>

          {sizeColorQuantity === 0 && (
            <Typography component="span" className={classes.soldout}>
              sold out
            </Typography>
          )}
        </div>

        <Rating
          name={"" + product.id}
          value={product.rating}
          precision={0.5}
          size={isSmallScreen ? "small" : "medium"}
          className={classes.rating}
          readOnly={true}
        />

        <div className={classes.productContent}>
          <Typography variant="h3" className={classes.title}>
            {product.description.name}
          </Typography>

          <div className={classes.priceLabel}>
            <Typography component="span" className={classes.price} style={{ marginLeft: "0px", fontSize: isSmallScreen ? "14px" : "15px" }}>
              {product.bargain === "YES" ? "Offering Price ₹" : "Price ₹"}
            </Typography>
            <Typography component="span" className={classes.price} >
              {getPrice(product)}
            </Typography>
          </div>
          {product.attributes && product.attributes.length > 0 && (
            <div className={classes.sizeLabel}>
              Sizes:{" "}
              {product.attributes.map((size, index, arr) => (
                <Typography
                  component="span"
                  className={classes.size}
                  key={index}
                >
                  {size.optionCode === "Watch Size"
                    ? "One Size"
                    : size.optionCode === "Bag Size"
                      ? "One Size"
                      : size.optionCode === "Toy Size"
                        ? "One Size"
                        : size.optionCode}
                  {index !== arr.length - 1 ? "," : ""}
                </Typography>
              ))}
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};