import React, { createContext, useState } from "react";

// Create Context
export const PaginationContext = createContext();

export const PaginationProvider = ({ children }) => {
  // Store pagination state here
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  return (
    <PaginationContext.Provider value={{ rowsPerPage, page, setRowsPerPage, setPage }}>
      {children}
    </PaginationContext.Provider>
  );
};
