import { Tooltip, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

const ProductOptions = ({ product, classes, handleSetAttribute, handleMiniCart, miniCartOpen, setBundleColor, setAttributeIds, setSizeForBargain, setColorForBargain, setCodeForBargain, setIdForBargain }) => {
    const [sizes, setSizes] = useState(null);
    const [colors, setColors] = useState([]);
    const [defaultColor, setDefaultColor] = useState("");
    const [colorSelected, setColorSelected] = useState(null);
    const [selectedSizes, setSelectedSizes] = useState(null);
    const [totalPcsSum, setTotalPcsSum] = useState(0);
    const { authenticated, account, alert } = useSelector((state) => state.auth);

    useEffect(() => {
        if (product?.bundleType === "sizes") {
            let values = [];

            for (let i = 0; i < product?.attributes?.length; i++) {
                for (let j = 0; j < product?.attributes[i]?.attributeOptionValues?.length; j++) {
                    values.push(product?.attributes[i]?.attributeOptionValues[j])
                }
            }

            const unique = [];
            const seen = new Set();
            values.forEach(item => {
                if (!seen.has(item["colorCode"])) {
                    unique.push(item);
                    seen.add(item["colorCode"]);
                }
            });
            setColors(unique)

        }
    }, [product])

    const getColorAttributesMap = (colors, attributes) => {
        const colorAttributesMap = {};

        // Loop through each color object
        colors.forEach(color => {
            const colorCode = color?.colorCode;
            const associatedAttributes = [];
            // Loop through each attribute
            attributes.forEach(attribute => {
                // Check if the attribute has option values
                if (attribute.attributeOptionValues) {
                    // Loop through each option value within the attribute
                    attribute.attributeOptionValues.forEach(optionValue => {
                        // If the colorCode matches, add the attributeId to the associatedAttributes
                        if (optionValue.colorCode === colorCode) {
                            associatedAttributes.push(attribute);
                        }
                    });
                }
            });

            // If the colorCode exists, add to the result object
            if (associatedAttributes.length > 0) {
                colorAttributesMap[colorCode] = associatedAttributes;
            }
        });
        return colorAttributesMap;
    };

    useEffect(() => {

        if (selectedSizes) {
            const optionCodes = selectedSizes.map(size => size.optionCode);
            setSizeForBargain(optionCodes);
        }
    }, [selectedSizes]);


    useEffect(() => {
        if (colors && colors.length > 0) {
            const firstColor = colors[0];
            setColorSelected(firstColor);
            setDefaultColor(firstColor.colorCode);
            setColorForBargain(firstColor.optionValueCode);
            setCodeForBargain(firstColor.colorCode);

            if (product?.attributes) {
                const sizes = getColorAttributesMap(colors, product.attributes);
                setSizes(sizes);
            }
            handleSetAttribute(firstColor);
        }
    }, [colors, product?.attributes]);

    useEffect(() => {
        const attributeIdsWithMatchingOptionValues =
            sizes?.[colorSelected?.colorCode]?.flatMap(item =>
                item?.attributeOptionValues
                    ?.filter(option => option?.colorCode === colorSelected?.colorCode)
                    ?.map(option => ({
                        attributeId: option?.attributeId,
                        optionValueCode: option?.optionValueCode,
                        minQuantity: option?.minQuantity
                    })) || []
            ) || [];

        // Group by `optionValueCode` to check which `attributeId` share the same `optionValueCode`
        const groupedByOptionValueCode = attributeIdsWithMatchingOptionValues.reduce((acc, { attributeId, optionValueCode, minQuantity }) => {
            if (!acc[optionValueCode]) {
                acc[optionValueCode] = [];
            }
            acc[optionValueCode].push({ attributeId, minQuantity });
            return acc;
        }, {});

        // Filter out those `attributeId` entries where the `optionValueCode` has more than one match
        const finalAttributeIds = Object.values(groupedByOptionValueCode)
            .filter(group => group.length > 1) // Only keep groups with more than one attributeId
            .flat();

        // Calculate the sum of `totalPcs` for the filtered attributeIds
        const totalPcsSum = finalAttributeIds.reduce((sum, { minQuantity }) => sum + (minQuantity || 0), 0);

        // Update states with the results
        setAttributeIds(finalAttributeIds.map(({ attributeId }) => attributeId)); // Set only the attributeIds
        setIdForBargain(finalAttributeIds.map(({ attributeId }) => attributeId));
        setSelectedSizes(sizes?.[colorSelected?.colorCode]);
        setTotalPcsSum(totalPcsSum); // Save the sum of totalPcs if required
    }, [colorSelected]);

    useEffect(() => {
        setDefaultColor(colorSelected?.colorCode)
    }, [colorSelected])

    const handleColorSelection = (item) => {
        setColorSelected(item)
        setBundleColor(item);
        setColorForBargain(item?.optionValueCode);
        setCodeForBargain(item?.colorCode);
    }

    return (
        <div>
            <Typography
                variant="subtitle2"
                className={classes.sizelable}
            >
                Available Sizes and Colors
            </Typography>
            <div className={classes.sizediv}>
                {colors?.map(
                    (item, index) => (
                        <Tooltip
                            classes={{ tooltip: classes.customTooltip }}
                            title={`${item.optionValueCode}`}
                            key={index}
                            arrow
                            placement="top"
                            onClick={() => handleColorSelection(item)}
                        >
                            <div
                                className={classes.colorDiv}
                                style={
                                    item.colorCode === colorSelected?.colorCode
                                        ? {
                                            border: `2px solid #086fcf`,
                                        }
                                        : { border: "2px solid #edeff2" }
                                }
                            >
                                <button
                                    className={classes.colorbox}
                                    style={{
                                        background: `${item.colorCode}`,
                                        borderColor: `${item.colorCode}`,
                                        outline: "none",
                                    }}
                                    onClick={() =>
                                        handleSetAttribute(item, index)
                                    }
                                />
                            </div>
                        </Tooltip>
                    )
                )}
                <div style={{ margin: "auto" }}>
                    {product.bargain === "NO" &&
                        product.buyable &&
                        !miniCartOpen && (
                            <>
                                <Typography
                                    variant="body2"
                                    style={{
                                        textAlign: "center",
                                        color: "#27B23E",
                                    }}
                                >
                                    Hi, There!
                                </Typography>
                                <Tooltip
                                    title={
                                        <h2
                                            style={{
                                                fontSize: 15,
                                                fontFamily:
                                                    "'Fira Sans', sans-serif",
                                            }}
                                        >
                                            Hi{" "}
                                            {authenticated
                                                ? account.firstName
                                                : ""}
                                            , I am Minicart. I help you to shop
                                            multiple attributes of this product.
                                        </h2>
                                    }
                                    arrow
                                    className={classes.tooltip}
                                >
                                    <div
                                        style={{
                                            padding: 10,
                                        }}
                                    >
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                "/images/bag.png"
                                            }
                                            alt="stockX"
                                            height="50px"
                                            className={classes.swingimage}
                                            onClick={handleMiniCart}
                                        />
                                    </div>
                                </Tooltip>
                            </>
                        )}
                </div>
            </div>
            <Typography
                variant="subtitle2"
                className={classes.sizelable}
            >
                Sizes Selected
            </Typography>
            <div
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                }}
            >
                <div className={classes.sizediv} >
                    {sizes?.[colorSelected?.colorCode]?.sort((a, b) => a.optionId - b.optionId)
                        .map((item, index) => (
                            <button
                                key={index}
                                className={classes.sizeboxItem}
                                style={{ border: `2px solid #086fcf`, color: "#086fcf" }}
                            >
                                {["Watch Size", "Bag Size", "Toy Size"].includes(item.optionCode)
                                    ? "One Size"
                                    : item.optionCode.replace(/\bSize\b/, "").trim()}
                            </button>
                        ))}
                </div>
            </div>
            <Typography
                variant="subtitle2"
                className={classes.sizelabel}
            >
                Total Pieces ({totalPcsSum})
            </Typography>

            {/* <div className={classes.piecesSizediv}>
                {sizes?.[colorSelected?.colorCode] // Access the array for the selected color code
                    ?.filter(sizeObj => sizeObj.attributeOptionValues?.some(attr => attr.colorCode === colorSelected?.colorCode)) // Filter objects where `attributeOptionValues` has matching `colorCode`
                    ?.flatMap(sizeObj => sizeObj.attributeOptionValues || []) // Flatten `attributeOptionValues` arrays
                    ?.filter(attr => attr.colorCode === colorSelected?.colorCode) // Filter by `colorCode` inside `attributeOptionValues`
                    ?.map((item, index) => (
                        <div
                            key={index}
                            className={classes.piecesColorDiv}
                            style={{
                                border: `2px solid ${item.colorCode === colorSelected?.colorCode ? "#086fcf" : "#edeff2"}`,
                            }}
                        >
                            <button className={classes.piecesColorbox}>
                                {item.minQuantity}
                            </button>
                        </div>
                    ))}
            </div> */}
        </div>
    )
}

export default ProductOptions