import React, { useEffect, useRef, useState } from "react";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { Box, MenuItem, TextField } from "@material-ui/core";
import { isStringNullorEmpty } from "../../../util/commonUtil/commonUtil";
import { uploadCustOrdVideo } from "../../../redux/actions/customerActions";
import { useDispatch, useSelector } from "react-redux";
import { alertSnackBar } from "../../../redux/actions/checkoutActions";


const useStyles = makeStyles((theme) => ({
    brand: {
        color: "#27B23E",
    },
    address: {
        color: "#04AA6D",
        marginTop: theme.spacing(1),
    },
    paper: {
        padding: "10px"
    },
    dialog: {
        "& .MuiListItemText-primary": {
            fontSize: 12,
            fontFamily: "'Fira Sans', sans-serif",
        },
        "& .MuiListItemSecondaryAction-root": {
            fontFamily: "'Fira Sans', sans-serif",
            fontWeight: 600,
            fontSize: 12,
        },
    },
    canclebutton: {
        fontFamily: "'Fira Sans', sans-serif",
        color: "#fff",
        backgroundColor: "#DF002C",
    },
    confirmbutton: {
        fontFamily: "'Fira Sans', sans-serif",
        color: "#fff",
        backgroundColor: "#3f51b5",
    },
    dialogBackdrop: {
        backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
}));

const NUMERIC_ONLY = /^[0-9]+$/;
const AlPHANUMERIC_ONLY = /^[ A-Za-z0-9_@./#&+-]+$/;
const SPECIAL_ONLY = /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/;
const NUMERIC_SPECIAL_ONLY = /^[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/;

export const CancelReason = (props) => {

    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const { custVidResponse } = useSelector((state) => state.notification);
    const { open, handleClose, submitCancelReason, cancelData, orderData, item } = props;
    const [cancelReason, setCancelReason] = useState("");
    const [errors, setErrors] = useState({});
    const [cancelComments, setCancelComments] = useState("");
    const [confirm, setConfirm] = useState(false);
    const [video, setVideo] = useState(null);
    const [orderId, setOrderId] = useState(null);

    const cancelReasonsList = [
        {
            id: 1,
            reason: "Delivery Delayed"
        },
        {
            id: 2,
            reason: "Wrong Address Given"
        },
        {
            id: 3,
            reason: "Product Not Required"
        },
        {
            id: 4,
            reason: "Cheaper Alternative Found"
        },
        {
            id: 5,
            reason: "Price decreased in current sales"
        },
        {
            id: 6,
            reason: "Product Not Satisfied"
        },
        {
            id: 7,
            reason: "Product is cheaper at other sites"
        },
        {
            id: 8,
            reason: "Not interested in product now"
        },
        {
            id: 9,
            reason: "Delivery delayed for occasion"
        },
        {
            id: 10,
            reason: "Found better deal"
        },
        {
            id: 11,
            reason: "Not available during delivery"
        },
        {
            id: 12,
            reason: "Inventory stuck"
        },
        {
            id: 13,
            reason: "Needed another brand"
        }
    ];


    const handleCloseForModal = () => {
        setErrors({});
        setCancelReason("");
        setCancelComments("");
        handleClose();
    };

    useEffect(() => {
        setOrderId(item?.orderId);
    }, [open]);

    const handleCancelReasonChange = (e) => {
        setCancelReason(e.target.value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            cancelReason: e.target.value ? "" : prevErrors.cancelReason,
        }));
    };

    const handleCancelCommentsChange = (e) => {
        setCancelComments(e.target.value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            cancelComments: e.target.value ? "" : prevErrors.cancelComments,
        }));
    };

    const inputValidation = () => {
        let valid = true;
        let errors = {};

        if (isStringNullorEmpty(cancelReason)) {
            valid = false;
            errors["cancelReason"] = "Please select cancel reason";
        }

        if (isStringNullorEmpty(cancelComments)) {
            valid = false;
            errors["cancelComments"] = "Please provide cancel comments";
        }

        setErrors(errors);
        return valid;
    }

    const handleCancelInputs = () => {
        if (inputValidation()) {
            submitCancelReason(cancelReason, cancelComments, cancelData);
            setCancelReason('')
            setCancelComments('')
        }
    };

    const handleButtonClick = () => {
        if (inputValidation()) {
            document.getElementById(`video-${orderId}`).click();
        } else {
            dispatch(alertSnackBar("error", "Please fill in the required fields before uploading."));
        }
    };

    const handleFileSelect = (event) => {
        let file = event.target.files[0];

        if (!file) {
            dispatch(alertSnackBar("info", "No file selected"));
            return;
        }

        const fileSizeMB = file.size / (1024 * 1024);
        if (fileSizeMB > 50) { 
            dispatch(alertSnackBar("error", "File size exceeds 50MB. Please upload a smaller file."));
            return;
        }

        setVideo(file);
        setConfirm(true);
    };

    const handleConfirmUpload = (customerId, orderId) => {
        if (!video) {
            dispatch(alertSnackBar("error", "Please select a video before confirming."));
            return;
        }
        handleUploadVid(customerId, orderId, video);
    };

    const handleUploadVid = async (customerId, orderId, video) => {
        if (!video) {
            console.warn("No video file provided");
            return;
        }
        const formData = new FormData();
        formData.append("video", video);
        formData.append("cust_id", customerId);
        formData.append("order_id", orderId);

        try {
            const response = await dispatch(uploadCustOrdVideo(formData));
            await dispatch(alertSnackBar("success", response?.message));
            if (inputValidation()) {
                submitCancelReason(cancelReason, cancelComments, cancelData);
                setCancelReason('')
                setCancelComments('')
            }
        } catch (error) {
            
        }
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleCloseForModal}
            keepMounted
            className={classes.dialog}
            fullWidth={true}
            aria-describedby="alert-dialog-slide-description"
            maxWidth={"sm"}
        >

            <DialogTitle
                id="responsive-dialog-title"
                style={{ textAlign: "center" }}
            >
                Cancel Order Reason
            </DialogTitle>

            <DialogContent style={{ textAlign: "center" }}>
                <Box className={classes.paper}>
                    {/*<form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleClose();
                        }}
                    >*/}
                    <form className={classes.root1} autoComplete="off">
                        <TextField
                            select
                            variant="outlined"
                            label="Select Cancel Reason *"
                            value={cancelReason}
                            onChange={handleCancelReasonChange}
                            style={{ width: 530 }}
                            inputProps={{ id: "outlined-age-simple" }}
                        >
                            <MenuItem value={""}>
                                <em></em>
                            </MenuItem>

                            {
                                cancelReasonsList.length > 0 ? (
                                    cancelReasonsList.map((reason) => (
                                        <MenuItem key={reason.id} value={reason.reason}>
                                            {reason.reason}
                                        </MenuItem>

                                    ))
                                ) : (
                                    <h1 style={{ display: 'none' }}></h1>
                                )
                            }

                        </TextField>
                        <p style={{ color: "red" }}>{errors.cancelReason ? errors.cancelReason : ""}</p>


                        <TextField
                            autoFocus
                            margin="dense"
                            id="cancelComments"
                            label="Cancel Comments *"
                            type="text"
                            value={cancelComments}
                            fullWidth
                            variant="standard"
                            onChange={handleCancelCommentsChange}
                        />
                        <p style={{ color: "red" }}>{errors.cancelComments ? errors.cancelComments : ""}</p>
                    </form>
                </Box>
            </DialogContent>

            <DialogActions>

                <Button autoFocus onClick={handleButtonClick} color="primary">
                    Upload Video and Cancel Order
                </Button>

                <input
                    type="file"
                    accept="video/*"
                    id={`video-${orderId}`}
                    style={{ display: "none" }}
                    onChange={handleFileSelect}
                />

                <Dialog
                    open={confirm}
                    onClose={() => setConfirm(false)}
                    BackdropProps={{
                        className: classes.dialogBackdrop,
                    }}
                >
                    <DialogTitle id="confirm-dialog">Confirm Video Upload</DialogTitle>
                    <DialogContent>
                        <p>
                            Are you sure you want to upload this video for the selected order?
                        </p>
                        <p>
                            Once uploaded, you won't be able to edit or remove it. Please confirm to proceed.
                        </p>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setConfirm(false)} color="secondary" variant="contained" className={classes.canclebutton}>
                            No
                        </Button>
                        <Button
                            onClick={() => {
                                handleConfirmUpload(orderData?.customerId, orderId);
                                setConfirm(false);
                            }}
                            color="primary" variant="contained" className={classes.confirmbutton}
                        >
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>

                <Button autoFocus onClick={handleCloseForModal} color="primary">
                    Close
                </Button>

            </DialogActions>
        </Dialog>
    );
};
