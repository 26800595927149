import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Editor,
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";

import { updateAttribute } from "../../../redux/actions/sellerActions";
import { MyEditor } from "../editor/Editor";

//material ui
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { IconButton } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { validatePositiveNumberEvent } from './../../../util/commonUtil/commonUtil';
import { PaginationContext } from "../../../util/componentUtil/PaginationContext";

const useStyles = makeStyles((theme) => ({
  dilougDiv: {
    background: "#F7F7F7",
  },
  appBar: {
    position: "relative",
    background: "#53E7BC",
    marginBottom: "1rem",
  },
  title: {
    fontSize: 20,
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 600,
    color: "#fff",
    margin: "auto",
  },
  labelRoot: {
    fontSize: 14,
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 400,
    color: "#666666",
  },
  input: {
    fontSize: 14,
    fontFamily: "'Fira Sans', sans-serif",
  },
  submitdiv: {
    "& > *": {
      margin: theme.spacing(1),
    },
    textAlign: "center",
  },
  submitButton: {
    "&:hover": {
      background: "#4AD0AA",
      color: "#FFFFFF",
    },
  },
  cancelButton: {
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    textTransform: "none",
    "&:hover": {
      background: theme.palette.error.main,
      color: theme.palette.error.contrastText,
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NUMERICSPEL_ONLY = /^[0-9]+$/; 	
const NUMDOT_ONLY = /^[0-9.]+$/; 	
export const EditAttribute = (props) => {
  const classes = useStyles();
  const { account } = useSelector((state) => state.auth);
  const {
    open,
    productId,
    attribute,
    optionId,
    optionCode,
    defaultAttribute,
    handleCloseDialog,
  } = props;

  const dispatch = useDispatch();

  const [size, setSize] = useState({ id: "", code: "" });
  const [styleCode, setStyleCode] = useState("");
  const [itemSkuCode, setItemSkuCode] = useState("");
  const [styleDesc, setStyleDesc] = useState("");
  const [tradeDiscount, setTradeDiscount] = useState("");
  const [price, setPrice] = useState("");
  const [mrp, setMrp] = useState("");
  const [quantity, setQuantity] = useState("");
  const [minQuantity, setMinQuantity] = useState(1);
  const [ean, setEan] = useState("");
  const [height, setHeight] = useState("");
  const [length, setLength] = useState("");
  const [weight, setWeight] = useState("");
  const [width, setWidth] = useState("");
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [colorCode, setColorCode] = useState("");
  const [attributeDefault, setAttributeDefault] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const { page, rowsPerPage } = useContext(PaginationContext);
  const start = page * rowsPerPage;

  useEffect(() => {
    if (Object.keys(attribute).length !== 0) {
      setSize((prevState) => ({
        ...prevState,
        code: optionCode,
        id: optionId,
      }));
      setStyleCode(attribute.styleCode);
      setItemSkuCode(attribute.item_sku);
      setStyleDesc(attribute.styleDescription);
      setTradeDiscount(attribute.tradeDisCount ? attribute.tradeDisCount : "");
      setPrice(attribute.priceFinal);
      setMrp(attribute.attributePrice);
      setQuantity(attribute.attributeQuantity);
      setMinQuantity(attribute.minQuantity);
      setEan(attribute.ean);
      setHeight(attribute.articleSpecification.height);
      setLength(attribute.articleSpecification.length);
      setWeight(attribute.articleSpecification.weight);
      setWidth(attribute.articleSpecification.width);

      const blocksFromHTML = convertFromHTML(attribute.attiributeDescription);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(EditorState.createWithContent(state));
      setColorCode(attribute.colorCode);
      setAttributeDefault(defaultAttribute);
    }
  }, [attribute]); // eslint-disable-line react-hooks/exhaustive-deps

  const formValidation = () => {
    let formErrors = {};
    let inputsValid = true;

    if (!size) {
      inputsValid = false;
      formErrors["size"] = "this field is required";
    }

    if (!colorCode) {
      inputsValid = false;
      formErrors["color"] = "this field is required";
    }
    if (!styleCode) {
      inputsValid = false;
      formErrors["styleCode"] = "this field is required";
    }
    if (!itemSkuCode) {
      inputsValid = false;
      formErrors["itemSkuCode"] = "this field is required";
    }
    if (!mrp) {
      inputsValid = false;
      formErrors["mrp"] = "this field is required";
    }
    if (!quantity) {
      inputsValid = false;
      formErrors["quantity"] = "this field is required";
    }
    if (!minQuantity && minQuantity !== 0) {
      inputsValid = false;
      formErrors["minQuantity"] = "Please enter minimum buyable quantity.";
    }
    // if (!ean) {
    //   inputsValid = false;
    //   formErrors["ean"] = "this field is required";
    // }
    if (!height) {
      inputsValid = false;
      formErrors["height"] = "this field is required";
    }
    if (!length) {
      inputsValid = false;
      formErrors["length"] = "this field is required";
    }
    if (!weight) {
      inputsValid = false;
      formErrors["weight"] = "this field is required";
    }
    if (!width) {
      inputsValid = false;
      formErrors["width"] = "this field is required";
    }
    // if (images.length === 0) {
    //   inputsValid = false;
    //   formErrors["images"] = "this field is required";
    // }
    setFormErrors(formErrors);
    return inputsValid;
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (formValidation()) {
      const attributeData = {
        articleSpecification: {
          dimensionUnitOfMeasure: "cm",
          height: Number(height),
          id: 0,
          length: Number(length),
          weight: Number(weight),
          weightUnitOfMeasure: "g",
          width: Number(width),
        },
        attiributeDescription: draftToHtml(
          convertToRaw(editorState.getCurrentContent())
        ),
        attributeDefault: attribute.optionValueDefault,
        attributeDisplayOnly: true,
        ean: ean,
        id: 0,
        item_sku: itemSkuCode,
        minQuantity: Number(minQuantity),
        option: {
          categoryCode: "string",
          code: size.code,
          id: size.id,
          readOnly: true,
          type: "string",
        },
        optionValue: {
          code: attribute.optionValueCode,
          defaultValue: true,
          id: attribute.optionValueId,
          image: "string",
          price: "string",
          sortOrder: 0,
        },
        priceFinal: Number(price),
        productAttributePrice: Number(mrp),
        productAttributeQuantity: Number(quantity),
        productId: 0,
        sortOrder: 0,
        styleCode: styleCode,
        styleDescription: styleDesc,
        tradeDisCount: tradeDiscount,
      };
      dispatch(
        updateAttribute(
          attributeData,
          productId,
          attribute.attributeId,
          account.id,
          rowsPerPage,
          start
        )
      );
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      TransitionComponent={Transition}
      className={classes.dilougDiv}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseDialog}
            aria-label="close"
            style={{
              outline: "none",
              border: "2px solid #000000",
              padding: 0,
              color: "#000000",
            }}
          >
            <CloseIcon />
          </IconButton>

          <Typography variant="h6" className={classes.title}>
            Edit Attributes
          </Typography>
        </Toolbar>
      </AppBar>

      {attribute && Object.keys(attribute).length !== 0 && (
        <Container component="main" maxWidth="lg">
          <form onSubmit={handleFormSubmit} className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  disabled
                  fullWidth
                  id="size"
                  name="size"
                  label="Size *"
                  variant="outlined"
                  size="small"
                  value={size.code}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  InputProps={{ classes: { input: classes.input } }}
                  helperText={formErrors.size ? formErrors.size : ""}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  disabled
                  fullWidth
                  id="color"
                  name="color"
                  label="color *"
                  variant="outlined"
                  size="small"
                  value={colorCode}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  InputProps={{ classes: { input: classes.input } }}
                  helperText={formErrors.color ? formErrors.color : ""}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  id="styleCode"
                  fullWidth
                  name="styleCode"
                  label="Style Code *"
                  variant="outlined"
                  size="small"
                  value={styleCode}
                  onChange={(e) => setStyleCode(e.target.value)}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  InputProps={{ classes: { input: classes.input } }}
                  helperText={formErrors.styleCode ? formErrors.styleCode : ""}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  id="itemSkuCode"
                  fullWidth
                  name="itemSkuCode"
                  label="Item SKU *"
                  variant="outlined"
                  size="small"
                  value={itemSkuCode}
                  onChange={(e) => setItemSkuCode(e.target.value)}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  InputProps={{ classes: { input: classes.input } }}
                  helperText={
                    formErrors.itemSkuCode ? formErrors.itemSkuCode : ""
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  id="styleDesc"
                  fullWidth
                  name="styleDesc"
                  label="Style Description"
                  variant="outlined"
                  size="small"
                  value={styleDesc}
                  onChange={(e) => setStyleDesc(e.target.value)}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  InputProps={{ classes: { input: classes.input } }}
                  helperText={formErrors.styleDesc ? formErrors.styleDesc : ""}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  id="tradeDiscount"
                  fullWidth
                  name="tradeDiscount"
                  label="Trade Discount"
                  variant="outlined"
                  size="small"
                  type="tel"
                  value={tradeDiscount}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value !== "" && !NUMDOT_ONLY.test(value)) {
                      return;
                    }
                    setTradeDiscount(e.target.value)}}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  InputProps={{ classes: { input: classes.input } }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  id="mrp"
                  fullWidth
                  name="mrp"
                  type="tel"
                  label="Price excluding GST *"
                  variant="outlined"
                  size="small"
                  value={mrp}
                  onChange={(e) =>{
                    const value = e.target.value;
                    if (value !== "" && !NUMERICSPEL_ONLY.test(value)) {
                      return;
                    }
                    setMrp(e.target.value)}}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  InputProps={{ classes: { input: classes.input } }}
                  helperText={formErrors.mrp ? formErrors.mrp : ""}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  id="price"
                  fullWidth
                  name="price"
                  type="tel"
                  label="Final Price "
                  variant="outlined"
                  size="small"
                  value={price}
                  onChange={(e) =>{
                    const value = e.target.value;
                    if (value !== "" && !NUMERICSPEL_ONLY.test(value)) {
                      return;
                    }
                    setPrice(e.target.value)}}
                  
                  onKeyUp={ (e) => validatePositiveNumberEvent(e) }
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  InputProps={{ classes: { input: classes.input } ,
                                inputProps: { min: 1, max: 999999 }
                  }}
                />
              </Grid>

              

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  id="quantity"
                  fullWidth
                  name="quantity"
                  type="tel"
                  label="Quantity *"
                  variant="outlined"
                  size="small"
                  value={quantity}
                  onChange={(e) =>{
                    const value = e.target.value;
                    if (value !== "" && !NUMERICSPEL_ONLY.test(value)) {
                      return;
                    }
                    setQuantity(e.target.value)}}
                  onKeyUp={(e) => validatePositiveNumberEvent(e) }
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  InputProps={{ classes: { input: classes.input } ,  inputProps: { min: 1, max: 999999 }  }}
                  helperText={formErrors.quantity ? formErrors.quantity : ""}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  id="minQuantity"
                  fullWidth
                  name="minQuantity"
                  type="tel"
                  label="Minimum Buy *"
                  variant="outlined"
                  size="small"
                  value={minQuantity}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value !== "" && !NUMERICSPEL_ONLY.test(value)) {
                      return;
                    }
                    setMinQuantity(e.target.value)}}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  InputProps={{ classes: { input: classes.input } }}
                  helperText={
                    formErrors.minQuantity ? formErrors.minQuantity : ""
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  id="ean"
                  fullWidth
                  name="ean"
                  label="EAN"
                  variant="outlined"
                  size="small"
                  value={ean}
                  onChange={(e) => setEan(e.target.value)}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  InputProps={{ classes: { input: classes.input } }}
                  helperText={formErrors.ean ? formErrors.ean : ""}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  id="length"
                  fullWidth
                  name="length"
                  type="tel"
                  label="length *"
                  variant="outlined"
                  size="small"
                  value={length}
                  onChange={(e) =>{
                    const value = e.target.value;
                    if (value !== "" && !NUMDOT_ONLY.test(value)) {
                      return;
                    }
                    setLength(e.target.value)}}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  InputProps={{ classes: { input: classes.input } }}
                  helperText={formErrors.length ? formErrors.length : ""}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  id="width"
                  fullWidth
                  name="width"
                  type="tel"
                  label="width *"
                  variant="outlined"
                  size="small"
                  value={width}
                  onChange={(e) =>{
                    const value = e.target.value;
                    if (value !== "" && !NUMDOT_ONLY.test(value)) {
                      return;
                    }
                    setWidth(e.target.value)}}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  InputProps={{ classes: { input: classes.input } }}
                  helperText={formErrors.width ? formErrors.width : ""}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  id="height"
                  fullWidth
                  name="height"
                  type="tel"
                  label="height *"
                  variant="outlined"
                  size="small"
                  value={height}
                  onChange={(e) =>{
                    const value = e.target.value;
                    if (value !== "" && !NUMDOT_ONLY.test(value)) {
                      return;
                    }
                    setHeight(e.target.value)}}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  InputProps={{ classes: { input: classes.input } }}
                  helperText={formErrors.height ? formErrors.height : ""}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  id="weight"
                  fullWidth
                  name="weight"
                  type="tel"
                  label="Weight in grams*"
                  variant="outlined"
                  size="small"
                  value={weight}
                  onChange={(e) =>{
                    const value = e.target.value;
                    if (value !== "" && !NUMDOT_ONLY.test(value)) {
                      return;
                    }
                    setWeight(e.target.value)}}
                  onKeyUp={ (e) => validatePositiveNumberEvent(e) }
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  InputProps={{ classes: { input: classes.input }  , inputProps: { min: 1, max: 99999 } }}
                  helperText={formErrors.weight ? formErrors.weight : ""}
                />
              </Grid>

              <Grid item xs={12}>
                <MyEditor
                  Editor={Editor}
                  EditorState={EditorState}
                  RichUtils={RichUtils}
                  editorState={editorState}
                  setEditorState={setEditorState}
                />
              </Grid>

              <Grid item xs={12}>
                <div className={classes.submitdiv}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    className={classes.submitButton}
                  >
                    Update Attribute
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.cancelButton}
                    onClick={handleCloseDialog}
                  >
                    Cancel
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        </Container>
      )}
    </Dialog>
  );
};
