import { ActionTypes } from "../constants/actionTypes";

const initialState = {
  loading: false,
  serverError: false,
  error: null,
  categorylist: [],
  productlist: [],
  productObject: {},
  deleteSuccess: false,
  options: [],
  productCountList: [],
  colors: [],
  sellerProfile: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.LOADING_SELLER_DATA:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.SELLER_SET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ActionTypes.CATEGORY_LIST:
      return {
        ...state,
        categorylist: action.payload,
        loading: false,
      };
    case ActionTypes.SELLER_PRODUCT_LIST:
      return {
        ...state,
        loading: false,
        productlist: action.payload,
      };
      case ActionTypes.SELLER_TOTAL_PRODUCT_LIST:
      return {
        ...state,
        loading: false,
        productObject: action.payload,
      };

    case ActionTypes.CATEGORY_PRODUCT_COUNT:
      return {
        ...state,
        loading: false,
        productCountList: action.payload,
      };
    case ActionTypes.SELLER_SERVER_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
        serverError: action.payload,
      };
    case ActionTypes.ADD_PRODUCT:
      return {
        ...state,
        productlist: [ action.payload, ...state.productlist],
      };
    case ActionTypes.DELETE_PRODUCT:
      return {
        ...state,
        productlist: state.productlist.filter(
          (item) => item.id !== action.payload
        ),
      };
    case ActionTypes.EDIT_PRODUCT:
      return {
        ...state,
        productlist: state.productlist.map((item) =>
          item.id === action.payload.id ? { ...action.payload } : item
        ),
      };
    case ActionTypes.CLEAR_ERRORS_SELLER:
      return {
        ...state,
        loading: false,
        serverError: false,
        error: null,
      };
    case ActionTypes.SET_ATTRIBUTE_OPTIONS:
      return {
        ...state,
        options: action.payload.sizes || [],
        colors: action.payload.colors || [],
      };
    case ActionTypes.GET_SELLER_PROFILE:
      return {
        ...state,
        loading: false,
        sellerProfile: action.payload,
      };
    case ActionTypes.UPDATE_SELLER_BANK_DETAILS:
      return {
        ...state,
        sellerProfile:
          state.sellerProfile.id === action.payload.id
            ? { ...action.payload }
            : state.sellerProfile,
      };
      case ActionTypes.CHANGE_PRODUCT_LOADING:
      return {
        ...state,
        loadingActive: true,
      };
    case ActionTypes.CHANGE_PRODUCT_STATUS:
      return {
        ...state,
        loadingActive: false,
        productlist: state.productlist.map((item) =>
          item.id === action.payload.id ? { ...action.payload } : item
        ),
      };
    default:
      return state;
  }
}
