import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActionTypes } from "../redux/constants/actionTypes";
import Spinner from "../util/spinner/spinner";
import { DemoItem } from "./DemoItem";
import { Paginator } from "./Paginator";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import { Grid, MenuItem, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Divider from "@material-ui/core/Divider";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  rootDiv: {
    "& .MuiButtonBase-root": {
      outline: "none",
    },
    "& .MuiGrid-root :hover": {
      textDecoration: "none",
    },
    "& .MuiCardMedia-img": {
      objectFit: "contain",
      fontSize: "12px",
      fontWeight: "bold",
      color: "#383838",
      fontFamily: "'Fira Sans', sans-serif",
      textAlign: "center",
    },
    "& .MuiFormControlLabel-label": {
      fontSize: "15px",
      fontFamily: "'Fira Sans', sans-serif",
      color: "#383838",
    },
    "& .MuiSelect-select": {
      fontSize: "14px",
      fontWeight: 500,
      fontFamily: "'Fira Sans', sans-serif",
      color: "#140101",
      [theme.breakpoints.down('xs')]: {
        fontSize: "12px",
      },
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 140,
    [theme.breakpoints.down('xs')]: {
      minWidth: 120,
      margin: theme.spacing(0.5),
    },
  },
  menuItem: {
    fontSize: "14px",
    fontFamily: "'Fira Sans', sans-serif",
    lineHeight: 2.13,
    color: "#140101",
    [theme.breakpoints.down('xs')]: {
      fontSize: "12px",
    },
  },
  selectSortBy: {
    fontSize: "16px",
    fontWeight: "bold",
    fontFamily: "'Fira Sans', sans-serif",
    lineHeight: 1.09,
    color: "#140101",
    [theme.breakpoints.down('xs')]: {
      fontSize: "14px",
    },
  },
  sort: {
    fontSize: "16px",
    fontWeight: "bold",
    fontFamily: "'Fira Sans', sans-serif",
    lineHeight: 2.13,
    color: "#140101",
    [theme.breakpoints.down('xs')]: {
      fontSize: "14px",
    },
  },
  typebuttons: {
    fontSize: "14px",
    fontFamily: "'Fira Sans', sans-serif",
    color: "#140101",
    textTransform: "none",
    [theme.breakpoints.down('xs')]: {
      fontSize: "12px",
      padding: "4px 8px",
    },
  },
  activetypebuttons: {
    fontSize: "14px",
    fontFamily: "'Fira Sans', sans-serif",
    color: "#27B23E",
    textTransform: "none",
    borderBottom: "2px solid #27B23E",
    [theme.breakpoints.down('xs')]: {
      fontSize: "12px",
      padding: "4px 8px",
    },
  },
  dropdownStyle: {
    marginTop: 48,
  },
  showMore: {
    color: "#0000FF",
    textTransform: "uppercase",
    fontSize: 14,
  },
  productGridContainer: {
    padding: "1rem 0 1rem 1rem",
    margin: 0,
    [theme.breakpoints.down('xs')]: {
      padding: "0.5rem",
    },
  },
  stickyBox: {
    position: "sticky",
    top: 80,
    backgroundColor: "#fff",
    zIndex: 3,
    paddingLeft: "1rem",
    [theme.breakpoints.down('xs')]: {
      top: 60,
      paddingLeft: "0.5rem",
    },
  },
  typeButtonsContainer: {
    display: "flex",
    padding: "11px",
    [theme.breakpoints.down('xs')]: {
      padding: "6px",
      flexWrap: 'wrap',
    },
  },
}));

const ProductList = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const { filterProducts, loading } = useSelector((state) => state.home);
  const { selectedPriceSort, setSelectedPriceSort } = props;
  const dispatch = useDispatch();
  const [type, setType] = useState(selectedPriceSort);
  const [page, setPage] = useState(1);

  const handleChangeSortBy = (event) => {
    const val = event.target.value;
    setSelectedPriceSort(val);
    dispatch({
      type: ActionTypes.PRODUCT_SORT_BY_CLICKED,
      payload: val,
    });
  };

  const handleChangeType = (value) => {
    setType(value);
    dispatch({
      type: ActionTypes.PRODUCT_PRICE_TYPE_CLICKED,
      payload: {
        type: value,
        page: 1,
      },
    });
  };

  const handleChangePage = (event, newValue) => {
    setPage(newValue);
    dispatch({
      type: ActionTypes.PRODUCT_PAGE_NUMBER_CLICKED,
      payload: newValue,
    });
  };

  useEffect(() => {
    return () => {
      dispatch({
        type: ActionTypes.PRODUCT_PRICE_TYPE_CLICKED,
        payload: "all",
      });
      setType("all");
    };
  }, []);

  return (
    <Grid item md={9} lg={10} xs={12}>
      <Grid className={classes.rootDiv}>
        <Hidden xsDown>
          <Grid item container alignItems="center" className={classes.stickyBox}>
            <Grid item sm={8} xs={12}>
              <div className={classes.typeButtonsContainer}>
                <Typography className={classes.sort}>Product Type:</Typography>
                <div>
                  <Button
                    className={
                      type === "all"
                        ? classes.activetypebuttons
                        : classes.typebuttons
                    }
                    onClick={() => handleChangeType("all")}
                  >
                    All
                  </Button>
                  <Button
                    className={
                      type === "YES"
                        ? classes.activetypebuttons
                        : classes.typebuttons
                    }
                    onClick={() => handleChangeType("YES")}
                  >
                    Bargain
                  </Button>
                  <Button
                    className={
                      type === "NO"
                        ? classes.activetypebuttons
                        : classes.typebuttons
                    }
                    onClick={() => handleChangeType("NO")}
                  >
                    Fixed
                  </Button>
                </div>
              </div>
            </Grid>

            <Grid
              item
              container
              justifyContent={isSmallScreen ? "flex-start" : "flex-end"}
              sm={4}
              xs={12}
              style={{ padding: isSmallScreen ? "0 0.5rem" : "0 2.5rem 0.22rem 0" }}
            >
              <FormControl
                variant="outlined"
                className={classes.formControl}
                size="small"
              >
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={selectedPriceSort}
                  onChange={handleChangeSortBy}
                  className={classes.selectSortBy}
                  MenuProps={{
                    classes: { paper: classes.dropdownStyle },
                  }}
                >
                  <MenuItem value="all" className={classes.menuItem}>
                    All
                  </MenuItem>
                  <MenuItem value="hightolow" className={classes.menuItem}>
                    Price: High to Low
                  </MenuItem>
                  <MenuItem value="lowtohigh" className={classes.menuItem}>
                    Price: Low to High
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Hidden>

        <Divider />

        <Grid
          item
          container
          spacing={isSmallScreen ? 1 : 2}
          className={classes.productGridContainer}
        >
          {loading ? (
            <Spinner />
          ) : Object.keys(filterProducts).length > 0 &&
            filterProducts.products.length > 0 ? (
            filterProducts.products.map((product) => (
              <Grid item xs={6} sm={6} md={3} key={product.id}>
                <DemoItem product={product} />
              </Grid>
            ))
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: "100%", height: "100%" }}
            >
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
                style={{ height: "50vh" }}
              >
                <Box alignSelf="center" pb={8}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/searchMatchesNotFound404.png"
                    }
                    alt="searchMatchesNotFoundImage"
                    style={{ width: isSmallScreen ? "200px" : "100%" }}
                  />
                </Box>
                <Box
                  alignSelf="center"
                  style={{
                    fontSize: isSmallScreen ? "1.5rem" : "2rem",
                    fontWeight: 400,
                    fontFamily: "'Fira Sans', sans-serif",
                    textAlign: "center",
                  }}
                >
                  We couldn't find any matches!
                </Box>
                <Box
                  alignSelf="center"
                  pt={2}
                  style={{
                    fontSize: isSmallScreen ? "0.9rem" : "1rem",
                    fontWeight: 300,
                    fontFamily: "'Fira Sans', sans-serif",
                    textAlign: "center",
                    padding: isSmallScreen ? "0 1rem" : 0,
                  }}
                >
                  Please check the spelling or try searching something else
                </Box>
              </Box>
            </Box>
          )}
        </Grid>

        {Object.keys(filterProducts).length > 0 &&
          filterProducts.products.length > 0 &&
          filterProducts.totalPages > 1 && (
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{ padding: "30px 0 100px 0" }}
            >
              <Paginator
                variant="outlined"
                shape="rounded"
                page={page}
                handleChangePage={handleChangePage}
                count={filterProducts.totalPages}
              />
            </Grid>
          )}
      </Grid>
    </Grid>
  );
};

export default React.memo(ProductList);