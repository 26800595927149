import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Typography, Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import XIcon from "@mui/icons-material/X";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import shopImage from "../assets/images/shop.png";

const useStyles = makeStyles((theme) => ({
  footer: {
    width: "100%",
    backgroundColor: "#333",
    padding: "16px 20px",
    display: "flex",
    flexWrap: "wrap",
    "& .MuiListItemText-primary": {
      fontSize: 10,
      color: "#a29e9e",
      fontFamily: "'Fira Sans', sans-serif",
      textTransform: "capitalize",
      lineHeight: "180%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "8px 10px",
      display: "flex",
      flexDirection: "column",
    },
  },
  heading: {
    fontSize: 14,
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 600,
    color: "#fff",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
      margin: "2px 0 5px 0",
    },
  },
  address: {
    fontSize: 14,
    fontFamily: "'Fira Sans', sans-serif",
    color: "#fff",
    textTransform: "capitalize",
    margin: "5px 0px 10px 0px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  text: {
    fontSize: 10,
    fontFamily: "'Fira Sans', sans-serif",
    color: "#a29e9e",
    lineHeight: "180%",
    [theme.breakpoints.down("sm")]: {
      fontSize: 7,
    },
  },
  subheading: {
    fontSize: 10,
    fontFamily: "'Fira Sans', sans-serif",
    textTransform: "uppercase",
    fontWeight: 500,
    margin: "8px 0 4px 0",
    color: "#a29e9e",
    [theme.breakpoints.down("sm")]: {
      fontSize: 5,
    },
  },
  spancontent: {
    fontSize: 9,
    fontFamily: "'Fira Sans', sans-serif",
    color: "#fff",
  },
  newsletterInput: {
    margin: "10px 0 0 0",
    borderStyle: "none",
    backgroundColor: "#4d4d4d",
    borderRadius: "6px",
    color: "#a29e9e",
  },
  newsletterButton: {
    margin: "16px 0 0 0",
    backgroundColor: "#f00",
    fontSize: 10,
    borderStyle: "none",
    "&:hover": {
      backgroundColor: "#f00",
    },
  },
  socialIconText: {
    fontSize: 10,
    fontFamily: "'Fira Sans', sans-serif",
    textTransform: "uppercase",
    fontWeight: 500,
    margin: "16px 0 0 0",
    color: "#a29e9e",
  },
  icon: {
    borderRadius: "50%",
    color: "#fff",
    padding: 3,
    fontSize: 24,
    "&:hover": {
      color: "rgba(0, 0, 0, .3)",
      transform: "scale(1.3)",
      transition: "all .2s ease-in-out",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      padding: 2,
    },
  },
  footer2: {
    width: "100%",
    height: "45px",
    padding: "15px 20px",
    backgroundColor: "#252121",
    position: "relative",
  },
  copyRight: {
    color: "rgb(255, 255, 255)",
    fontSize: "12px",
    textAlign: "center",
  },
  list: {
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      "& .MuiListItem-root": {
        width: "auto",
        padding: "4px 8px",
      },
    },
  },
  signUpButton: {
    textTransform: "none",
    fontSize: "13px",
    fontWeight: 600,
    borderRadius: "0.375rem",
    padding: "0.375rem 1.25rem",
    height: "40px",
    backgroundColor: "#ff9d00",
    [theme.breakpoints.down("sm")]: {
      fontSize: "11px",
      padding: "0.25rem 0.75rem",
      height: "32px",
    },
  },
  Shop: {
    width: "81px",
    height: "90px",
    [theme.breakpoints.down("sm")]: {
      width: "60px",
      height: "60px",
      margin: "0",
    },
  },
  socialContainer: {
    display: "flex",
    // flexWrap: "wrap",
    justifyContent: "center",
    gap: "4px",
    margin: "4px 0",
    // marginTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      margin: "2px 0",
    },
  },
  socialHeading: {
    textAlign: "center",
    width: "100%",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(0.5),
    },
  },
  imageSellerContainer: {
    display: "flex",
    alignItems: "flex-start",
    gap: "16px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      justifyContent: "flex-start",
      width: "100%",
      marginBottom: "16px",
    },
  },
  sellerContent: {
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    },
  },
  footerGridContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  linksColumn: {
    minWidth: "200px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
    },
  },
  socialWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  socialWrapperMobile: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(1),
    },
  },
}));

export const Footer = ({ setShowSiteMap, showSiteMap }) => {
  const classes = useStyles();

  return (
    <Fragment>
      <div className={classes.footer}>
        <Grid container spacing={1}>
          {/* Seller Section */}
          <Grid item xs={12} md={4}>
            <div className={classes.imageSellerContainer}>
              <img src={shopImage} className={classes.Shop} alt="shop" />
              <div className={classes.sellerContent}>
                <Typography variant="h6" className={classes.heading}>
                  Are you an established retailer?
                </Typography>
                <Link to="/seller-signup" style={{ textDecoration: "none" }}>
                  <Button
                    component={Link}
                    to="/seller-signup"
                    variant="contained"
                    className={classes.signUpButton}
                  >
                    Become a Seller
                  </Button>
                </Link>
              </div>
            </div>
          </Grid>

          {/* Links Section */}
          <Grid item xs={12} md={4}>
            <List className={classes.list}>
              <ListItem
                button
                component={Link}
                to="/terms-and-conditions"
                className={classes.list}
                target="_self"
              >
                <ListItemText primary="Terms & conditions" />
              </ListItem>

              <ListItem
                button
                component={Link}
                to="/privacy-policy"
                className={classes.list}
                target="_self"
              >
                <ListItemText primary="Privacy Policy" />
              </ListItem>

              <ListItem
                button
                component={Button}
                onClick={() => setShowSiteMap(!showSiteMap)}
                className={classes.list}
                target="_self"
              >
                <ListItemText primary="Sitemap" />
              </ListItem>

              <ListItem
                button
                component={Link}
                to="/shipping-policy"
                className={classes.list}
                target="_self"
              >
                <ListItemText primary="Delivery & Shipping Policy" />
              </ListItem>

              <ListItem
                button
                component={Link}
                to="/Refund-policy"
                className={classes.list}
                target="_self"
              >
                <ListItemText primary="Refund Policy" />
              </ListItem>

              <ListItem
                button
                component={Link}
                to="/about-us"
                className={classes.list}
                target="_self"
              >
                <ListItemText primary="About US" />
              </ListItem>

              <ListItem
                button
                component="a"
                href="https://outxtock.com/contact-us"
                className={classes.list}
              >
                <ListItemText primary="Contact Us" />
              </ListItem>
            </List>
          </Grid>

          {/* Social Media Section */}
          <Grid item xs={12} md={4}>
            <div
              className={`${classes.socialWrapper} ${classes.socialWrapperMobile}`}
            >
              <Typography
                variant="h6"
                className={`${classes.heading} ${classes.socialHeading}`}
              >
                Follow Us On
              </Typography>
              <div className={classes.socialContainer}>
                <IconButton aria-label="twitter">
                  <XIcon
                    onClick={() => window.open("https://twitter.com/Stockxbid")}
                    fontSize="medium"
                    className={classes.icon}
                    style={{ backgroundColor: "#000000" }}
                  />
                </IconButton>
                <IconButton aria-label="facebook">
                  <FacebookIcon
                    onClick={() =>
                      window.open(
                        "https://www.facebook.com/outxtock?mibextid=ZbWKwL"
                      )
                    }
                    fontSize="small"
                    className={classes.icon}
                    style={{ backgroundColor: "#4267B2" }}
                  />
                </IconButton>
                <IconButton aria-label="instagram">
                  <InstagramIcon
                    onClick={() =>
                      window.open(
                        "https://www.instagram.com/outxtock?igsh=MW5yZHh2bG1ya3FiNA=="
                      )
                    }
                    fontSize="small"
                    className={classes.icon}
                    style={{ backgroundColor: "#8a50ca" }}
                  />
                </IconButton>
                <IconButton aria-label="linkedin">
                  <LinkedInIcon
                    onClick={() => window.open("https://www.Linkedin.com")}
                    fontSize="small"
                    className={classes.icon}
                    style={{ backgroundColor: "#0077B5" }}
                  />
                </IconButton>
                <IconButton aria-label="youtube">
                  <YouTubeIcon
                    onClick={() => window.open("https://youtube.com/@outxtock")}
                    fontSize="small"
                    className={classes.icon}
                    style={{ backgroundColor: "#f00" }}
                  />
                </IconButton>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className={classes.footer2}>
        <Typography component="p" className={classes.copyRight}>
          © 2024 Stockxbid Technologies Pvt Ltd. All rights reserved.
        </Typography>
      </div>
    </Fragment>
  );
};
