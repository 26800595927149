import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

/* redux action */
import {
  deleteAttributImage,
  uploadProductImage,
} from "../../../redux/actions/sellerActions";

/* material ui */
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { IconButton } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import Container from "@material-ui/core/Container";
import { Grid } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { Button } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { useEffect } from "react";
import Loading from "../../../util/spinner/Loading";
import Axios from "axios";
import { PaginationContext } from "../../../util/componentUtil/PaginationContext";

/* material ui css */
const useStyles = makeStyles((theme) => ({
  dilougDiv: {
    background: "#F7F7F7",
  },
  appBar: {
    position: "relative",
    background: "#53E7BC",
    marginBottom: "1rem",
  },
  title: {
    fontSize: 20,
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 600,
    color: "#fff",
    margin: "auto",
  },
  submitdiv: {
    margin: theme.spacing(2),
    textAlign: "center",
  },
  submitButton: {
    fontWeight: 600,
    borderRadius: "0.375rem",
    padding: "0.375rem 1.25rem",
    "&:hover": {
      background: "#4AD0AA",
      color: "#FFFFFF",
    },
  },
  img: {
    width: "100%",
    border: "1px solid #27B23E",
    margin: "auto",
    minHeight: 220,
  },
  removeIcon: {
    borderRadius: "50%",
    position: "absolute",
    top: "-15px",
    right: "-15px",
    padding: 0,
    background: "#555",
    border: "3px solid #53E7BC",
    color: "#fff",
    boxShadow: "0 2px 6px rgba(0,0,0,0.5), inset 0 2px 4px rgba(0,0,0,0.3)",
    transition: "background 0.5s",
    WebkitTransition: "background 0.5s",
    textShadow: "0 1px 2px rgba(0,0,0,0.5)",
    "&:hover": {
      background: "#E54E4E",
      top: "-15px",
      right: "-15px",
      padding: "2px",
    },
  },
  paper: {
    padding: theme.spacing(2),
    margin: 40,
    textAlign: "center",
    backgroundColor: "#EBDEF0",
  },
  uploadIcon: {
    color: "#53E7BC",
  },
  label: {
    fontFamily: "'Fira Sans', sans-serif",
    fontSize: 14,
    fontWeight: 600,
  },
  uploadbutton: {
    color: "#fff",
    fontFamily: "'Fira Sans', sans-serif",
    backgroundColor: "#53E7BC",
    textTransform: "capitalize",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#53E7BC",
    },
  },
  input: {
    display: "none",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/* Image base url */
const imageBaseUrl = process.env.REACT_APP_IMAGE_URL;
const bunnyCdnUrl = process.env.REACT_APP_BUNNY_CDN_URL

export const AttributeImage = (props) => {
  const classes = useStyles();
  const { images, attributeId, openImgDialog, handleClose } = props;
  const { account } = useSelector((state) => state.auth);
  const [allImgSize, setAllImgSize] = useState(0);
  const [imageData, setImageData] = useState();
  const [loading, setLoading] = useState(false);
  const [imgAttribute, setImgAttribute] = useState();
  const [uploadError, setUploadError] = useState("");
  const [imageUrls, setImageUrls] = useState([]);
  const dispatch = useDispatch();
  const { page, rowsPerPage } = useContext(PaginationContext);
  const start = page * rowsPerPage;

  /* function to delete attribute image by id */
  const handleDeleteImage = (id) => {
    dispatch(deleteAttributImage(id, account.id, rowsPerPage, start));
    setUploadError("")
  };

  /* function to handle upload images  */
  const handleImageUpload = (e) => {
    setAllImgSize(0);
    let imgObject = [];
    let imgData = e.target.files;
    const data = {
      id: attributeId,
    };

    imgObject.push(e.target.files);
    for (let i = 0; i < imgObject[0].length; i++) {
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];

      if (allowedTypes.includes(imgObject[0][i].type)) {
        setAllImgSize((prev) => prev + imgObject[0][i].size);
        setLoading(true);
        setUploadError();
      } else {
        setLoading(false);
        setUploadError("Please upload only JPEG, JPG or PNG images.");
        return '';
      }
    }

    setImageData(imgData);
    setImgAttribute(data);
  };


  const handleCloseButton = () => {
    handleClose()
    setUploadError("")
  }

  useEffect(() => {
    const SubmitImages = () => {
      if (allImgSize > 0 && allImgSize <= 3145728) {
        setLoading(true)
        dispatch(uploadProductImage(imgAttribute, imageData, account.id, start, rowsPerPage ));

      } else {
        setLoading(false)
        setUploadError("Please upload the Images less then 3MB.");
      }
    };
    allImgSize > 0 && SubmitImages();
  }, [allImgSize]);

  useEffect(() => {
    // const checkImageOnBunny = async (url) => {
    //   try {
    //     const response = await Axios.post(`https://ci-sdd-cdn.stockxbid.com:3333/checkPathExists`, {
    //       path: url,
    //     });
    //     if (response.data.exists === true) {
    //       return `${bunnyCdnUrl}/${url}`;
    //     }
    //     else {
    //       return `${imageBaseUrl}/static/products/${url}`;
    //     }
    //   } catch (error) {
    //     // console.error('Error checking image on BunnyCDN:', error);
    //     return false;
    //   }
    // };

    const validateImages = async () => {
      const valid = await Promise.all(
        images.map(async (image) => {
          let url;
          if (image?.imageUrl?.includes(bunnyCdnUrl)) {
            url = `${image?.imageUrl}`
          } else {
            url = `${bunnyCdnUrl}${image?.imageUrl}`;
          }
          return url ? { id: image.id, url } : null;
        })
      );

      setImageUrls(valid.filter((img) => img !== null));
    };

    if (images?.length > 0) {
      validateImages();
    }
  }, [images]);


  return (
    <Dialog
      open={openImgDialog}
      fullScreen
      TransitionComponent={Transition}
      className={classes.dilougDiv}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseButton}
            aria-label="close"
            style={{
              outline: "none",
              border: "2px solid #000000",
              padding: 0,
              color: "#000000",
            }}
          >
            <CloseIcon />
          </IconButton>

          <Typography variant="h6" className={classes.title}>
            Update Attribute Images
          </Typography>
        </Toolbar>
      </AppBar>

      <Container component="main" maxWidth="lg">
        {loading && <Loading />}
        <Grid container spacing={2}>
          {imageUrls?.length > 0 &&
            imageUrls.map((image) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={image.id}>
                <div style={{ position: "relative" }}>
                  <img
                    src={`${image.url}`}
                    alt={image.imageName}
                    className={classes.img}
                  />

                  {!image.defaultImage && (
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={() => handleDeleteImage(image.id)}
                      aria-label="close"
                      className={classes.removeIcon}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </div>
              </Grid>
            ))}
        </Grid>

        <Paper className={classes.paper} variant="outlined" square>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <CloudUploadIcon className={classes.uploadIcon} />
            </Grid>

            <Grid item xs={12}>
              <Typography className={classes.label}>
                Upload a Attribute Images.
              </Typography>
              {uploadError && (
                <Typography style={{ color: "red" }}>{uploadError}</Typography>
              )}
            </Grid>

            <Grid item xs={12}>
              <input
                accept="image/*"
                className={classes.input}
                id="contained-button-file"
                type="file"
                multiple
                onChange={handleImageUpload}
              />
              <label htmlFor="contained-button-file">
                <Button
                  variant="contained"
                  component="span"
                  startIcon={<CloudUploadIcon />}
                  className={classes.uploadbutton}
                // disabled={loading}
                >
                  Choose a Image to Upload...
                </Button>
              </label>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Dialog>
  );
};
