import { ActionTypes } from "../constants/actionTypes";
import axios from "axios";
import { snackBarAlert } from "./deliveryActions";
import {isArrayNotEmpty} from "../../util/commonUtil/commonUtil";

const baseUrl = process.env.REACT_APP_BASE_URL;
const vendorResponse = process.env.REACT_APP_BARGAIN_RESPONSE;
const elasticSearchUrl = process.env.REACT_APP_ELASTIC_SEARCH_URL;
const ordVdUrl = process.env.REACT_APP_ORD_VD_URL;

/* Action to get all category list to authenticated seller */
export const getCategoryList = () => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING_SELLER_DATA });
    const { data } = await axios.get(`${baseUrl}/category`);
    dispatch({ type: ActionTypes.CATEGORY_LIST, payload: data.categories });
    dispatch({ type: ActionTypes.CLEAR_ERRORS_SELLER });
  } catch (error) {
    if (error?.response?.status === 500) {
      dispatch({
        type: ActionTypes.SELLER_SET_ERROR,
        payload: error.response.data.message,
      });
    } else {
      dispatch({
        type: ActionTypes.SELLER_SERVER_ERROR,
        payload: "Server error , try again later !!",
      });
    }
  }
};

/* Action to get all category list to authenticated seller */
export const getCategoryProductCount = () => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING_SELLER_DATA });
    const { data } = await axios.get(`${baseUrl}/private/productCategoryCount`);
    dispatch({ type: ActionTypes.CATEGORY_PRODUCT_COUNT, payload: data.categories });
    dispatch({ type: ActionTypes.CLEAR_ERRORS_SELLER });
  } catch (error) {
    if (error?.response?.status === 500) {
      dispatch({
        type: ActionTypes.SELLER_SET_ERROR,
        payload: error.response.data.message,
      });
    } else {
      dispatch({
        type: ActionTypes.SELLER_SERVER_ERROR,
        payload: "Server error , try again later !!",
      });
    }
  }
};



/* Action to get all authenticated seller products */
export const getSellerProducts = (count, start, id) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING_SELLER_DATA });
    const { data } = await axios.get(
      `${baseUrl}/products/byUser?count=${count}&lang=en&start=${start}&userId=${id}`
    );

    dispatch({ type: ActionTypes.SELLER_PRODUCT_LIST, payload: data.products });
    dispatch({ type: ActionTypes.SELLER_TOTAL_PRODUCT_LIST, payload: data})
  } catch (error) {
    if (error?.response?.status === 500) {
      dispatch({
        type: ActionTypes.SELLER_SET_ERROR,
        payload: error.response.data.message,
      });
    } else {
      dispatch({
        type: ActionTypes.SELLER_SERVER_ERROR,
        payload: "Please try again in a few minutes or log out and then log in once.",
      });
    }
  }
};


const fetchAllOrders = async (user, dispatch) => {

  try {
    dispatch({ type: ActionTypes.SELLINGS_DETAILS_ORDERS_REQUEST });
    const fetchOrders = await axios.get(`${baseUrl}/private/orders`);
   
    dispatch({
      type: ActionTypes.SELLINGS_DETAILS_ORDERS_SUCCESS,
      payload: {
        orders: fetchOrders.data.orders
      },
    });
  } catch (error) {
    dispatch({
      type: ActionTypes.SELLINGS_DETAILS_ORDERS_ERROR,
      payload: {
        error: null,
        orders: []
      }
    });
  }
};

export const uploadOrdVideo = (formData) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.SELLINGS_DETAILS_ORDERS_REQUEST });
    const response = await axios.post(`${ordVdUrl}/api/videos/sellerUpload`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
   
    dispatch({
      type: ActionTypes.SELLINGS_ORDERS_VIDEO_UPLOAD_SUCCESS,
      payload: {
        vidResponse: response
      },
    });
  } catch (error) {
    // dispatch({
    //   type: ActionTypes.SELLINGS_DETAILS_ORDERS_ERROR,
    //   payload: {
    //     error: null,
    //     orders: []
    //   }
    // });
  }
};


/* Action to get authenticated seller selling details */
export const getSellings = (user) => async (dispatch) => {
  await fetchAllBargains(user, dispatch);
  // await fetchAllOrders(user, dispatch);
};


export const fetchAllBargains = async (user, dispatch) => {

  try {
    dispatch({ type: ActionTypes.SELLINGS_DETAILS_BARGAINS_REQUEST });
    const fetchBargains = await axios.get(
      `${elasticSearchUrl}/api/pushNotification/${user.id}/${user.userType}`
    );
    dispatch({
      type: ActionTypes.SELLINGS_DETAILS_BARGAIN_SUCCESS,
      payload: {
        bargains: fetchBargains.data.data.hits.hits,
      },
    });
  } catch (error) {
    dispatch({
      type: ActionTypes.SELLINGS_DETAILS_BARGAINS_ERROR,
      payload: {
        error: null,
        bargains: []
      }
    });
  }
};

/* Action to send respose to customer bargain */
export const bargainResponse = (resData, id, msg) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.SELLINGS_BARGAINS_RESPONSE_REQUEST });
    await axios.post(`${vendorResponse}/api/vendorResponse`, resData);
    dispatch({ type: ActionTypes.SET_BARGAINS, payload: id });
    dispatch(snackBarAlert("success", msg));
  } catch (error) {
    dispatch({ type: ActionTypes.SELLINGS_BARGAIN_RESPONSE_ERROR });
    dispatch(snackBarAlert("error", "Something went wrong !!!"));
    // console.log("Error in bargainResponse: ",error);
  }
};

/* Seller product upload action */
export const addProduct = (productData) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/private/product`,
      productData
    );

    dispatch({ type: ActionTypes.ADD_PRODUCT, payload: data });
  } catch (error) {
    // console.log(error);
  }
};

/* action to delete product */
export const deleteProduct = (id) => async (dispatch) => {
  try {
    await axios.delete(`${baseUrl}/private/product/${id}`);
    dispatch({ type: ActionTypes.DELETE_PRODUCT, payload: id });
  } catch (error) {
    // console.log(error.response);
  }
};

/* action to Edit product */
export const editProduct = (editData, productId) => async (dispatch) => {
  try {
    const { data } = await axios.put(
      `${baseUrl}/private/product/${productId}`,
      editData
    );

    dispatch({ type: ActionTypes.EDIT_PRODUCT, payload: data });
  } catch (error) {
    // console.log(error);
  }
};

/* action to get size and color options */
// export const getOptions = (categoryId) => async (dispatch) => {
//   try {
//     const fetchColors = await axios.get(`${baseUrl}/private/colors`);
//     const fetchSize = await axios.get(
//       `${baseUrl}/private/product/options/${categoryId}`
//     );

//     Promise.any([fetchColors, fetchSize])
//       .then(([colors, sizes]) => {
//         dispatch({
//           type: ActionTypes.SET_ATTRIBUTE_OPTIONS,
//           payload: {
//             colors: colors.data,
//             sizes: sizes.data,
//           },
//         });
//         dispatch({ type: ActionTypes.CLEAR_ERRORS_SELLER });
//       })
//       .catch(function (error) {
//         // console.log(error);
//       });

//     // const { data } = await axios.get(
//     //   `${baseUrl}/private/product/options/${categoryId}`
//     // );

//     // dispatch({ type: ActionTypes.SET_ATTRIBUTE_OPTIONS, payload: data });
//     // dispatch({ type: ActionTypes.CLEAR_ERRORS_SELLER });
//   } catch (error) {
//     // console.log(error);
//   }
// };

export const getOptions = (categoryId) => async (dispatch) => {
  // console.log('Id coming is', categoryId);
  try {
    const size = await  axios.get(`${baseUrl}/private/product/options/${categoryId}`);
    const fetchColors = await   axios.get(`${baseUrl}/private/colors`);
    if (size.data?.options?.length > 0) {
      if (fetchColors?.data?.length > 0) {
        dispatch({
          type: ActionTypes.SET_ATTRIBUTE_OPTIONS,
          payload: { sizes: size.data.options, colors: fetchColors.data }
        }
        )
      }

    }
  } catch (error) {
    console.log("Fetch Error: getOptions ", error);
  }
};

/* action to add attribute to product */
export const addAttribute =
  (formData, productId, imagesData, sellerId, count, start) => async (dispatch) => {
    try {
      const { data } = await axios.post(
        `${baseUrl}/private/product/${productId}/attribute`,
        formData
      );
      dispatch(uploadProductImage(data, imagesData, sellerId, start, count));
    } catch (error) {
      // console.log(error);
    }
  };

/* seller image upload action */
export const uploadProductImage =
  (data, imagesData, id, start, count) => async (dispatch) => {
    try {
      const formData = new FormData();
      for (const key of Object.keys(imagesData)) {
        formData.append("file[]", imagesData[key]);
      }

      await axios.post(
        `${baseUrl}/private/attribute/${data.id}/images`,
        formData
      );
      dispatch(getSellerProducts(count, start, id));
      // dispatch({
      //   type: ActionTypes.SET_SNACKBAR,
      //   payload: {
      //     alert: "success",
      //     message: "Image Uploaded successfully.",
      //   },
      // });
    } catch (error) {
      // console.log(error);
    }
  };

/* action to delete option from attribute */
export const deleteAttributeOption =
  (productId, attributeId) => async (dispatch) => {
    try {
      const { data } = await axios.delete(
        `${baseUrl}/private/product/${productId}/attribute/${attributeId}/delete`
      );

      dispatch({ type: ActionTypes.EDIT_PRODUCT, payload: data });
    } catch (error) {
      // console.log(error);
    }
  };

/* get seller profile by ID */
export const getSellerProfileById = (id) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING_SELLER_DATA });
    const { data } = await axios.get(`${baseUrl}/private/users/${id}`);
    dispatch({ type: ActionTypes.GET_SELLER_PROFILE, payload: data });
  } catch (error) {
    // console.log(error);
  }
};

/* create bank seller bank details */
export const createUserBankDetails =
  (userBankDetails, userId) => async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.LOADING_USER });
      const { data } = await axios.post(
        `${baseUrl}/private/user/${userId}/bankDetails`,
        userBankDetails
      );
      dispatch({ type: ActionTypes.ADD_SELLER_BANK_DETAILS, payload: data });
    } catch (error) {
      // console.log(error);
    }
  };

/* update seller bank details */
export const updateUserBankDetails =
  (userBankDetails, bankId, userId) => async (dispatch) => {
    try {
      const { data } = await axios.put(
        `${baseUrl}/private/user/${userId}/bankDetails/${bankId}`,
        userBankDetails
      );
      dispatch({ type: ActionTypes.UPDATE_SELLER_BANK_DETAILS, payload: data });
    } catch (error) {
      // console.log(error);
    }
  };

export const updateAttribute =
  (attributeData, productId, attributeId, sellerId, count, start) => async (dispatch) => {
    try {
      await axios.put(
        `${baseUrl}/private/product/${productId}/attribute/${attributeId}`,
        attributeData
      );
      dispatch(getSellerProducts(count, start, sellerId));
    } catch (error) {
      // console.log(error.response);
    }
  };

export const deleteAttributImage = (id, sellerId, count, start) => async (dispatch) => {
  try {
    await axios.delete(`${baseUrl}/private/products/attribute/image/${id}`);
    dispatch(getSellerProducts(count, start, sellerId));
    // dispatch(snackBarAlert("error", "Image Deleted successfully !!!"));
  } catch (error) {
    // console.log(error);
  }
};

export const changeProductStatus = (id, statusData) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.CHANGE_PRODUCT_LOADING });
    const { data } = await axios.patch(
      `${baseUrl}/private/product/${id}/visible`,
      statusData
    );
    dispatch({ type: ActionTypes.CHANGE_PRODUCT_STATUS, payload: data });
  } catch (error) {
    // console.log(error);
  }
};

export const productStatusAlert = (message) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_SNACKBAR,
    payload: {
      alert: "error",
      message: message,
    },
  });
};

export const orderAcceptSuccess = (data) => ({
  
  type: ActionTypes.ORDER_ACCEPT_SUCCESS,
  payload: data,  
});

export const orderAcceptError = (error) => ({
  type: ActionTypes.ORDER_ACCEPT_ERROR,
  payload: error,
});


//function to handle Cancel Order :  it will make Api call and UPdate Redux Store
export const cancelOrderseller = (readableOrderList, productItemId , customerOrders , orderNumber ,orderId, reduxData=[]) => async (dispatch) => {
  try {
     let response = await axios.post(
      `${baseUrl}/private/orders/cancelOrderBySeller`,
      readableOrderList 
      );
    
    let cancelResponse ; 
    if(isArrayNotEmpty(response.data.order)) {
          let products = response.data.order[0].products;
          if(isArrayNotEmpty(products)) {
                if(products[0].product != null &&  isArrayNotEmpty(products[0].product.productAttribute)) {
                  cancelResponse = products[0].product.productAttribute[0].cancelResponse;
                }
          }
    }
    
    response.data.order.forEach((itemApi)=>{
      reduxData.forEach((itemRedux)=>{
        if(itemRedux.id === itemApi.orderId){
          itemApi.products.forEach((upProd)=>{
            itemRedux.products.forEach((redItemProd)=>{
              if(upProd.product.orderProductId === redItemProd.id){
                redItemProd.orderStatus = upProd.product.productAttribute[0].orderStatus
              }
            })
          })
        }
      })
    });

      dispatch({
        type: ActionTypes.SELLER_CANCEL_REFRESH,
        payload: { orders : reduxData }
      });
 
  } catch (error) {
    // console.log("error in cancelOrderseller : ", error);
  }
};

//function to handle Accept Order :  it will make Api call and UPdate Redux Store
export const handleAcceptOrder = (acceptData, reduxData=[]) => async (dispatch) => {
  try {

    let response = await axios.post(
      `${baseUrl}/private/orders/acceptOrderBySeller`,
      acceptData
    )
    
      response.data.order.forEach((itemApi)=>{
      reduxData.forEach((itemRedux)=>{
        if(itemRedux.id === itemApi.orderId){
          itemApi.products.forEach((upProd)=>{
            itemRedux.products.forEach((redItemProd)=>{
              if(upProd.product.orderProductId === redItemProd.id){
                redItemProd.orderStatus = upProd.product?.productAttribute[0].orderStatus
              }
            })
          })
        }
      })
    });
    
    dispatch({
      type: ActionTypes.SELLER_CANCEL_REFRESH,
      payload: { orders : reduxData }
    });

  } catch (error) {
    // console.log("Error in  handleAcceptOrder:", error);
  }
};