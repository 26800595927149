import { ActionTypes } from "../constants/actionTypes";

const initialState = {
  loading: false,
  serverError: false,
  error: null,
  product: "",
  productOrder:"",
  reviews: "",
  related: [],
  miniCart: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.PRODUCT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        product: action.payload,
      };
      case ActionTypes.PRODUCT_DETAILS_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        productOrder: action.payload,
      };
    case ActionTypes.GET_PRODUCT_REVIEWS:
      return {
        ...state,
        reviews: action.payload,
      };
    case ActionTypes.GET_RELATED_PRODUCTS:
      return {
        ...state,
        related: [
          ...new Map([...state.related, ...action.payload].map((item) => [item.id, item])).values(),
        ],
      };
    case ActionTypes.PRODUCT_DETAILS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case ActionTypes.PRODUCT_DETAILS_SERVER_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
        serverError: action.payload,
      };
    case ActionTypes.PRODUCT_DETAILS_CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        serverError: false,
        error: null,
      };
    case ActionTypes.UPDATE_BARGAIN_TYPE:
      return {
        ...state,
        product:
          state.product.id === action.payload.id
            ? { ...action.payload }
            : state.product,
      };
    case ActionTypes.CREATE_MINI_CART:
      return {
        ...state,
        miniCart: action.payload,
      };
    default:
      return state;
  }
}
