import { ActionTypes } from "../constants/actionTypes";

const initialState = {
  loading: false,
  notification: [],
  error: null,
  customerOrders: {},
  cancelClicked:false,
  reviews: [],
  searchResult: [],
  searchQueries: [],
  orderError: null,
  custVidResponse: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.LOADING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.LOADED_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.SET_CUSTOMER_NOTIFICATION:
      return {
        ...state,
        loading: false,
        notification: action.payload,
      };
    case ActionTypes.SET_CUSTOMER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ActionTypes.SET_ORDERS_ERROR:
      return {
        ...state,
        loading: false,
        orderError: action.payload,
      };
    case ActionTypes.DELETE_NOTIFICATION:
      return {
        ...state,
        notification: state.notification.filter(
          (item) => item.max_date.hits.hits[0]?._id !== action.payload
        ),
      };
    case ActionTypes.GET_CUSTOMER_ORDERS:
      return {
        ...state,
        customerOrders: action.payload,
        loading: false,
        cancelClicked:false
      };
    case ActionTypes.CANCEL_CUSTOMER_ORDER:
      return {
        ...state,
        customerOrders: action.payload.customerOrders,
        cancelClicked:true
      };
    case ActionTypes.CLEAR_CUSTOMER_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
        cancelClicked:false,
        orderError: null,
      };
    case ActionTypes.GET_CUSTOMER_REVIEWS:
      return {
        ...state,
        reviews: action.payload,
        loading: false,
      };
    case ActionTypes.DELETE_CUSTOMER_REVIEW:
      return {
        ...state,
        reviews: state.reviews.filter((item) => item.id !== action.payload),
      };
    case ActionTypes.EDIT_CUSTOMER_REVIEW:
      return {
        ...state,
        reviews: action.payload,
      };
    case ActionTypes.CUSTOMER_SEARCH:
      return {
        ...state,
        searchResult: action.payload,
        loading: false,
      };
      case ActionTypes.CUSTOMER_ORDERS_VIDEO_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        custVidResponse: action.payload.custVidResponse,
      };
    case ActionTypes.SAVE_CUSTOMER_SEARCH_QURIES:
      return {
        ...state,
        searchQueries: state.searchQueries.find(
          (query) => query === action.payload
        )
          ? [...state.searchQueries]
          : state.searchQueries.length >= 5
          ? (state.searchQueries.shift(),
            [...state.searchQueries, action.payload])
          : [...state.searchQueries, action.payload],
      };
    default:
      return state;
  }
}
