import React, { useEffect, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Spinner from "../../../util/spinner/spinner";
import { SnackBar } from "../../SnackBar";
import { ConfirmDialog } from "../../ConfirmDialog";

/* redux actions */
import {
  deleteUnusedImageByName,
  getUnusedImages,
  removeAllUnUsedImages,
} from "../../../redux/actions/InventoryActions";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import IconButton from "@material-ui/core/IconButton";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import ListItemIcon from "@material-ui/core/ListItemIcon";

const useStyles = makeStyles((theme) => ({
  urlpaper: {
    padding: theme.spacing(2),
    margin: "auto",
    textAlign: "center",
    marginTop: 10,
    maxHeight: "500px",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
    "& .MuiListItemText-root": {
      overflow: "hidden",
    },
  },
  validationHeading: {
    fontSize: 24,
    fontWeight: "bold",
    fontFamily: "'Fira Sans', sans-serif",
    color: "#53e7bc",
    textDecoration: "underline",
  },
  listroot: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    "& .MuiListItemText-secondary": {
      fontSize: 13,
      fontWeight: 500,
      fontFamily: "'Fira Sans', sans-serif",
      textAlign: "center",
      margin: "auto",
    },
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "aliceblue",
    padding: 0,
    margin: 6,
  },
}));

const imgBaseUrl = process.env.REACT_APP_IMAGE_URL;

export const UnusedImage = () => {
  const classes = useStyles();
  const { account } = useSelector((state) => state.auth);
  const { loading, unused } = useSelector((state) => state.Inventory);
  const dispatch = useDispatch();

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [imageName, setImageName] = useState("");
  const [copy, setCopy] = useState(false); /* copy to clipboard */
  const [deleteAll, setDeleteAll] = useState(false);

  const [title, setTitle] = useState(""); /* confirm window title */
  const [subject, setSubject] = useState(""); /* confirm window subject */

  useEffect(() => {
    dispatch(getUnusedImages());
  }, []); /* eslint-disable-line react-hooks/exhaustive-deps */

  /* copy to clipboard */
  const copyToClipboard = (url) => {
    navigator.clipboard.writeText(url);
    setCopy(true);
  };

  const handleClickOpen = (imgName) => {
    if (imgName) {
      setConfirmOpen(true);
      setImageName(imgName);
      setTitle("Delete Image?.");
      setSubject("Are you sure you want to delete this Image.?");
    } else {
      setConfirmOpen(true);
      setDeleteAll(true);
      setTitle("Delete All Images?.");
      setSubject("Are you sure you want to delete all the Images.?");
    }
  };

  /* delete image from list */
  const handleDeleteImage = () => {
    dispatch(deleteUnusedImageByName(imageName));
    setDeleteAll(false);
  };

  /* remove all images */
  const handleRemoveAllImages = () => {
    dispatch(removeAllUnUsedImages(account.id));
    setDeleteAll(false);
  };

  const updateImageUrl = (url) => {
    const refText = "STOCKXBID-SELLER";
  
    if (url.includes(refText)) {
      const urlParts = url.split("/");
      const sellerId = urlParts[7];
      const imageName = urlParts[10];
  
      return `${imgBaseUrl}/static/PRODUCT/${sellerId}/IMAGE/${imageName}`;
    }
  
    return url; // Return original URL if condition is not met
  }; 

  return (
    <Fragment>
      {loading ? (
        <Spinner />
      ) : unused?.length > 0 ? (
        <Grid item xs={12}>
          <div style={{ textAlign: "end" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleClickOpen("")}
            >
              Delete All
            </Button>
          </div>
          <Paper className={classes.urlpaper} variant="outlined" square>
            <Typography className={classes.validationHeading}>
              Unused Images
            </Typography>

            <List className={classes.listroot} subheader={<li />}>
              {unused.map((item, index) => (
                <li key={index} className={classes.listSection}>
                  <ul className={classes.ul}>
                    <ListItem>
                      <ListItemAvatar>
                        <img
                          src={updateImageUrl(item?.securedImageUrl)}
                          alt={item.imageName}
                          width="100px"
                          height="100px"
                          style={{
                            border: "1px solid #53e7bc",
                            borderRadius: "50%",
                            marginRight: 10,
                          }}
                          loading="lazy"
                        />
                      </ListItemAvatar>
                      <ListItemText secondary={updateImageUrl(item?.securedImageUrl)} />
                      <ListItemIcon style={{ right: 0 }}>
                        <Tooltip
                          title={copy ? "copied" : "copy"}
                          placement="right-start"
                          arrow
                        >
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() =>
                              copyToClipboard(updateImageUrl(item?.securedImageUrl))
                            }
                            onMouseLeave={() => setCopy(false)}
                          >
                            <FileCopyIcon
                              size="small"
                              style={{ color: "#53e7bc" }}
                            />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="delete" placement="right-start" arrow>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => handleClickOpen(item.imageName)}
                          >
                            <DeleteIcon
                              size="small"
                              style={{ color: "#53e7bc" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </ListItemIcon>
                    </ListItem>
                  </ul>
                </li>
              ))}
            </List>
          </Paper>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Paper className={classes.urlpaper} variant="outlined" square>
            <Typography variant="body2">No Images.</Typography>
          </Paper>
        </Grid>
      )}

      <SnackBar />

      <ConfirmDialog
        title={title}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={deleteAll ? handleRemoveAllImages : handleDeleteImage}
      >
        {subject}
      </ConfirmDialog>
    </Fragment>
  );
};
